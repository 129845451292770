/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from 'theme-ui'
import Box from '../../Commons/Box'
import Image from '../../Commons/Image'
import { keyframes } from '@emotion/core'

import utils from '../../../utils'
import React, { useState } from 'react'
import Link from 'next/link'
import Text from '../../Commons/Text'

const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`

const styles = {
  header: {
    position: 'sticky',
    top: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: ['65px', '100px'],
    width: '100vw',
    px: ['1rem', '60px'],
    bg: 'primary',
    zIndex: '2',
  },
  navMobile: {
    position: 'fixed',
    top: ['65px', '100px'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100vh',
    width: '100vw',
    py: '1rem',
    paddingLeft: '20px',
    backgroundColor: 'primary_transparent',
    animationName: fadeIn,
    animationDuration: '0.5s',
    animationFillMode: 'backwards',
    zIndex: '10',
  },
}

export const Header = ({
  logo,
  navItems = [],
  utmParams,
  isMobile,
  ...props
}) => {
  const NavItems = ({ text, linkTo, style }) => (
    <Link href={`${linkTo}${utils.getQueryString(utmParams)}`}>
      <Text sx={{ variant: style }}>{text}</Text>
    </Link>
  )

  const [navMobile, setNavMobile] = useState(false)

  const mobileBurgerIcon = navMobile
    ? 'images/header_yellow_cross.png'
    : 'images/bars.svg'

  const mobileBurgerVariant = navMobile
    ? 'images.navbarCross'
    : 'images.navbarBars'

  return (
    <React.Fragment>
      <header sx={styles.header}>
        {logo && (
          <Link href={`/${utils.getQueryString(utmParams)}`}>
            <Image {...props} imageStyle="navbarLogo" src={logo} />
          </Link>
        )}
        {isMobile ? (
          <Box onClick={() => setNavMobile(!navMobile)}>
            <img
              src={mobileBurgerIcon}
              sx={{ variant: `${mobileBurgerVariant}` }}
            />
          </Box>
        ) : (
          <Box boxStyle="navbarItems">
            {navItems.map((item, id) => (
              <NavItems
                key={id}
                text={item?.text}
                linkTo={item?.link}
                style={'text.navbar'}
              />
            ))}
          </Box>
        )}
      </header>
      {navMobile && (
        <Box sx={styles.navMobile}>
          {navItems.map((item, id) => (
            <NavItems
              key={id}
              text={item?.text}
              linkTo={item?.link}
              style={'text.navbarMobile'}
            />
          ))}
        </Box>
      )}
    </React.Fragment>
  )
}
