/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Box from '../../Commons/Box'
import { PartnersList } from '../../PartnersList'
import { useRouter } from 'next/router'

const styles = {
  footer: (isMobile, isHomePage) => ({
    mt: 'auto',
    p: '20px 0 40px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100vw',
    backgroundColor: 'primary',

    ...(isMobile &&
      isHomePage && {
        backgroundImage: 'url("/images/footer-home-icons.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '90%',
      }),
  }),
  links: {
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    gap: ['30px', 0],
    fontSize: '15px',
    textAlign: 'center',
    mb: '10px',
    p: 0,
    list: {
      listStyleType: 'none',
    },
    text: {
      color: 'tertiary',
    },
  },
}

export const Footer = ({ links, partnersList, isMobile }) => {
  const router = useRouter()
  const isHomePage = router.asPath === '/'
  return (
    <footer sx={{ mt: 'auto' }}>
      <Box sx={styles.footer(isMobile, isHomePage)}>
        {!isHomePage && <PartnersList partnersList={partnersList} />}
        {links && (
          <ul sx={styles.links}>
            {links.map((link, id) => (
              <li key={`footer-link-${id}`} sx={styles.links.list}>
                <a
                  href={link.link}
                  rel="noreferrer"
                  target="_blank"
                  sx={styles.links.text}
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        )}
      </Box>
    </footer>
  )
}
