import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { Grid as GridUI, jsx } from 'theme-ui'

const Grid = React.forwardRef(
  ({ columns, children, gridStyle, styles }, ref) => {
    return (
      <GridUI
        ref={ref}
        columns={columns}
        sx={{ variant: `grids.${gridStyle}`, ...styles }}
      >
        {children}
      </GridUI>
    )
  },
)

export default Grid
