import Box from '../Commons/Box'
import Image from '../Commons/Image'
import Text from '../Commons/Text'
import Grid from '../Commons/Grid'

export const PartnersList = ({ partnersList = [], isHomePage = false }) => {
  const gridStyle = isHomePage ? 'partnersGridHome' : 'partnersGrid'
  return (
    <Box boxStyle="partnersContainer">
      <a
        href={partnersList[0]?.url}
        rel="noreferrer"
        target="_blank"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '1rem',
          width: '100%',
        }}
      >
        <Image imageStyle="hundredLogo" src={partnersList[0]?.logo} />
        <Text textStyle="partnerX">&#215;</Text>
      </a>
      <Grid gridStyle={gridStyle}>
        {partnersList?.slice(1).map((partner, id) => (
          <a
            key={id}
            style={{
              maxHeight: '50px',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
            href={partner?.url}
            rel="noreferrer"
            target="_blank"
          >
            <Image imageStyle="partnersLogo" src={partner?.logo} />
          </a>
        ))}
      </Grid>
    </Box>
  )
}
