/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'

import { Footer, Header } from '../index'
import { useIsMobile } from '../../hooks/useIsMobile'
import Box from '../Commons/Box'

const styles = {
  container: {
    margin: '0',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  content: (applyContentPadding) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    minHeight: '100vh',
    margin: '0',
    backgroundColor: 'primary',
    overflowX: 'hidden',
    width: '100vw',
    px: applyContentPadding ? '2rem' : '0',
  }),
}

const WithMainLayout = (Page, empty = false, applyContentPadding = true) =>
  function Component(props) {
    const { layout, utmParams, isMobile: isSsrMobile } = props
    const isMobile = useIsMobile(isSsrMobile)

    return (
      <main sx={styles.container}>
        <Box
          sx={{
            ...styles.content(applyContentPadding),
            ...(isMobile && { height: '100vh' }),
            background: empty ? 'white' : '',
          }}
        >
          {layout?.header && (
            <Header
              {...layout?.header}
              isMobile={isMobile}
              utmParams={utmParams}
              empty={empty}
            />
          )}
          <Page {...props}></Page>
          {!empty && layout?.footer && (
            <Footer {...layout?.footer} isMobile={isMobile} />
          )}
        </Box>
        <div data-iframe-height="1"></div>
      </main>
    )
  }

export default WithMainLayout
