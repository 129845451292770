import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { Image as ImageUI, jsx } from 'theme-ui'
const Image = React.forwardRef(({ src, imageStyle, styles, ...props }, ref) => {
  return (
    <picture sx={{ variant: `images.${imageStyle}.container` }}>
      <source srcSet={`${src}?fm=webp`} type="image/webp" />
      <source srcSet={`${src}?fm=png`} type="image/png" />
      <ImageUI
        sx={{ variant: `images.${imageStyle}`, ...styles }}
        src={src}
        ref={ref}
        {...props}
      />
    </picture>
  )
})
export default Image
